@import './assets/variables';
@import './assets/theme';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
ng-adsense {
  visibility: hidden;
}

.full-width-dialog {
  ::ng-deep .mat-dialog-container {
    max-width: 100vw;
  }
}

.new-bold {
  font-weight: $new-bold;
}
.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
.italic {
  font-style: italic;
}

/************* COLORS **** ***************/
.bg-color-blue {
  background-color: $eyescloud-blue;
}

.txt-color-blue {
  color: $eyescloud-blue;
}

.bg-color-orange {
  background-color: $new-orange;
}

.txt-color-orange {
  color: $eyescloud-orange;
}

a.active.active-orange p {
  color: $eyescloud-orange;
}

/****************************************/
//Tipo de fuente
@font-face {
  font-family: '$ecaptureDtech-font-family-Borna';
  src: url('assets/font/borna-regular-webfont.woff') format('woff');
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  overflow: hidden;
  overflow-x: hidden !important;
  font-size: 16px;
  position: fixed;
}

p,
span,
a {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

// Quitar al borrar el chat
#hubspot-messages-iframe-container {
  width: 0.1px !important;
  height: 0.1px !important;
  z-index: -999 !important;
}

.i18n-bold {
  font-weight: bold !important;
}

.i18n-underline {
  text-decoration: underline;
}

.i18n-color-black {
  color: $new-black !important;
}

.i18n-color-white {
  color: $new-white !important;
}

.i18n-color-blue {
  color: $eyescloud-blue !important;
}

.i18n-color-orange {
  color: $eyescloud-orange !important;
}

.i18n-color-new-orange {
  color: $new-orange !important;
}

.i18n-long-title,
.i18n-long-title-basque {
  letter-spacing: 0;
}

.mat-button-wrapper {
  font-weight: 300 !important;
}

.cursor-pointer {
  cursor: pointer;
}

p {
  margin: 0 !important;
  font-weight: inherit;
}

app-root {
  height: 100vh;
  display: block;
}

/*@media (min-width: 300px) and (max-width: 420px) {
  body {
    overflow: auto;
  }
}*/

/*@media (max-width: 599px) {
  main {
    padding: 0 !important;
  }
}*/

img {
  width: auto;
  height: auto;
}

button {
  border: none;
}

.mat-checkbox.mat-accent {
  .mat-checkbox-frame {
    border: 2px solid #f3f3f3;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: white;
    border: 2px solid #f3f3f3;
  }

  .mat-checkbox-checkmark-path {
    stroke: #4cd964 !important;
  }
}

.error-message {
  color: #dc3545;
  font-size: 15px;
}

a {
  cursor: pointer;
}

// .mat-dialog-container {
//   // border: 1px solid $eyescloud-orange;
// }

#chat {
  display: none;
}

.mat-button,
.mat-menu-item {
  outline: transparent !important;
}

div:focus,
img:focus,
a:focus,
p:focus,
button:focus,
input:focus {
  outline: none;
}

.none {
  display: none !important;
}

.sm-block {
  display: block !important;
}

button {
  outline: none !important;
}

/*IMAGE -CROPPER */

.cropper .move {
  border: 2px solid orange !important;
  border-radius: 100%;
}

.mat-select-arrow {
  border-image-source: url(/assets/images/select-ico.svg);
  border-left: 8px solid transparent !important;
  border-right: none !important;
  border-top: 4px solid transparent !important;
  border-image-repeat: stretch !important;
}

.i18n-initial-position-basque-letter-spacing {
  letter-spacing: -0.6px;
}

.i18n-notes-french-font-size {
  font-size: 0.438rem;
}

.french-ls,
.french-lsb {
  letter-spacing: -0.65px;
}

.i18n-long-steps-french {
  letter-spacing: -0.1px;
}

.i18n-bt-long-text-button {
  font-size: 0.54rem;
  letter-spacing: -0.5px;
}
.i18-area-long-text {
  font-size: 0.54rem;
  letter-spacing: -0.3px;
}
.i18-area-volume-long-text {
  font-size: 0.55rem;
  letter-spacing: -0.25px;
}
.i18-AV-volume-long-text {
  font-size: 0.55rem;
  letter-spacing: -0.2px;
}

.en-button-ls {
  letter-spacing: -1px;
}

.lg-title {
  letter-spacing: 0px;
}

.dropzone-ico {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 600px) {
  html,
  body {
    height: 100%;
    overflow: visible;
  }

  app-root {
    height: 100% !important;
  }

  .md-block {
    display: block !important;
  }

  .md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .md-none {
    display: none !important;
  }

  .i18n-BT-long-tittle {
    font-size: 0.56rem;
    letter-spacing: -0.4px;
  }
}

@media (min-width: 1280px) {
  .lg-block {
    display: block !important;
  }

  .i18n-tb-btn-size {
    letter-spacing: -0.4px;
    font-size: 0.46rem;
  }
  .i18n-bt-long-text-button {
    font-size: 0.5rem !important;
    letter-spacing: -0.5px !important;
  }
  .lg-none {
    display: none !important;
  }

  .lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .mat-button-wrapper {
    position: relative;
    bottom: 1px;
    left: -2px;
  }

  img {
    width: auto;
    height: auto;
  }

  .i18n-coordinates-french-letter-spacing,
  .i18n-zaxis-basque-letter-spacing,
  .i18n-segmentation-french-letter-spacing,
  .i18n-segmentation-basque-letter-spacing {
    letter-spacing: -0.32px;
  }

  .i18n-reference-system-french {
    letter-spacing: -0.1px;
  }

  .i18n-coordinates-error-french-letter-spacing,
  .i18n-long-title-basque {
    letter-spacing: -0.5px;
  }

  .i18n-coordinates-error-french-font-size {
    font-size: 0.4rem;
  }

  .i18n-initial-position-basque-font-size,
  .i18n-initial-position-french-font-size,
  .i18n-rotation-center-french-font-size {
    font-size: 0.47rem;
  }

  .i18n-rotation-center-basque-font-size {
    font-size: 0.45rem;
  }

  .i18n-segmentation-item-basque-letter-spacing {
    letter-spacing: -1.1px;
  }
  .i18n-seg-english-letter-spacing {
    letter-spacing: -0.9px;
  }

  .i18n-notes-french-font-size {
    letter-spacing: -0.2px;
  }

  .french-lsb,
  .euskera-lsb {
    font-size: 7px;
  }

  .i18n-long-steps-french {
    letter-spacing: -0.4px;
  }
  .i18-AV-volume-long-text {
    font-size: 0.536rem;
    letter-spacing: -0.35px;
  }
  .i18n-segmentation-bt-text {
    font-size: 0.49rem;
    letter-spacing: -0.6px;
  }
}

@include viewerScreenS {
  .lg-title {
    letter-spacing: -0.48px;
  }
}

@include viewerScreenX {
  .i18n-BT-long-tittle {
    font-size: 0.7rem;
    letter-spacing: -0.5px;
  }

  .lg-title {
    letter-spacing: -0.32px;
  }

  .i18n-french-exterior {
    font-size: 0.685rem;
    letter-spacing: -0.6px;
  }

  .i18n-sgeo-french {
    font-size: 0.688rem;
  }
}

@include screenX {
  .i18n-zaxis-french-letter-spacing {
    letter-spacing: -0.23px;
  }

  .i18n-reference-system-french {
    letter-spacing: -0.3px;
  }

  .i18-long-title-french {
    font-size: 0.7rem;
  }
  .i18n-BT-long-tittle {
    font-size: 0.7rem;
    letter-spacing: -0.5px;
  }
  .i18n-BT-rest-text {
    font-size: 0.7rem;
    letter-spacing: -0.5px;
  }
  .i18n-filter-long-title {
    font-size: 0.7rem;
    letter-spacing: -0.35px;
  }
  .i18-title-french-sizeX {
    font-size: 0.74rem;
  }
  .i18-area-long-text {
    font-size: 0.72rem;
    letter-spacing: -0.3px;
  }
  .i18-area-volume-long-text {
    font-size: 0.72rem;
    letter-spacing: -0.25px;
  }
  .i18-AV-volume-long-text {
    font-size: 0.69rem;
    letter-spacing: -0.38px;
  }
  .i18n-surface-long-title {
    font-size: 0.7rem;
  }

  .i18n-zaxis-basque-letter-spacing,
  .i18n-projection-french-letter-spacing,
  .i18n-virtual-tour-basque-letter-spacing,
  .french-button-spacing {
    letter-spacing: -0.45px;
  }

  .i18n-coordinates-french-letter-spacing {
    letter-spacing: -0.54px;
  }

  .i18n-coordinates-error-french-font-size {
    font-size: 0.563rem;
  }

  .i18n-initial-position-basque-letter-spacing {
    letter-spacing: -1px;
  }

  .i18n-initial-position-basque-font-size,
  .i18n-initial-position-french-font-size,
  .french-ls {
    font-size: 0.625rem;
  }
  .i18n-SS-long_tittle-french {
    font-size: 0.7rem;
  }
  .i18n-bt-long-text-button {
    font-size: 0.7rem;
    letter-spacing: -0.7px;
  }
  .i18n-rotation-center-basque-font-size {
    font-size: 0.55rem;
  }

  .i18n-rotation-center-french-font-size {
    font-size: 0.58rem;
  }

  .i18n-segmentation-french-letter-spacing,
  .matching-french-button-spacing {
    letter-spacing: -0.9px;
  }

  .i18n-segmentation-basque-letter-spacing {
    font-size: 0.65rem;
  }

  .i18n-long-title-basque,
  .i18n-long-steps-french {
    letter-spacing: -0.7px;
  }

  .i18n-notes-french-font-size {
    letter-spacing: -0.5px;
    font-size: 0.563rem;
  }

  .french-ls {
    letter-spacing: -0.4px;
  }

  .i18n-segmentation-bt-text {
    font-size: 0.6rem;
    letter-spacing: -0.4px;
  }
}

.transparent-backdrop {
  background-color: transparent;
}

// @media (min-width: 830px) and (orientation : landscape) {
//   body {
//     background-color: red !important;
//   }
// }

/* Importing Bootstrap SCSS file. */
@import '/node_modules/bootstrap/scss/bootstrap';
