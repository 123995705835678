@use '@angular/material' as mat;
@import '@angular/material/theming';

$my-blue: (
  50: #e0e9f1,
  100: #b3c9db,
  200: #80a5c3,
  300: #4d81ab,
  400: #266699,
  500: #004b87,
  600: #00447f,
  700: #003b74,
  800: #00336a,
  900: #002357,
  A100: #89adff,
  A200: #568aff,
  A400: #2367ff,
  A700: #0955ff,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$my-red: (
  50: #fce6e2,
  100: #f8c0b6,
  200: #f49686,
  300: #f06b55,
  400: #ec4c30,
  500: #e92c0c,
  600: #e6270a,
  700: #e32108,
  800: #df1b06,
  900: #d91003,
  A100: #ffffff,
  A200: #ffcfce,
  A400: #ff9e9b,
  A700: #ff8581,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$my-orange: (
  50: #fef5e7,
  100: #fde6c4,
  200: #fbd59d,
  300: #f9c475,
  400: #f8b758,
  500: #f7aa3a,
  600: #f6a334,
  700: #f5992c,
  800: #f39025,
  900: #f17f18,
  A100: #ffffff,
  A200: #fff6f0,
  A400: #ffdabd,
  A700: #ffcca3,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$eyescloud-primary: mat-palette($my-blue);
$eyescloud-accent: mat-palette($my-orange);
$eyescloud-warn: mat-palette($my-red);
// $eyescloud-theme: mat-light-theme(
//   $eyescloud-primary,
//   $eyescloud-accent,
//   $eyescloud-warn
// );

$eyescloud-theme: mat-light-theme(
  (
    color: (
      primary: $eyescloud-primary,
      accent: $eyescloud-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

$eyescloud-white: #fff;
$eyescloud-blue: #131bbc;
$eyescloud-green: #1e8121;
$eyescloud-dark-red: #770202;
$eyescloud-orange: #f1a227;
$eyescloud-disable: #8b8b8b;

$new-orange: #ffd900;
$new-black: #000000;
$new-white: #ffffff;
$new-red: #af050f;
$new-green: #007123;
$new-gray: #8b8a8a;
$new-gray-2: #d2d0d3;
$new-gray-3: #1d1d1b;
$mireia: #41968f;
$new-blue-log-incidence: #213bee;
$new-gray-log-incidence: #dddddd;
$new-gray2-log-incidence: #707070;
$new-gray3-log-incidence: #f5f5f5;
$new-gray4: #433c3c;
$new-gray-tongle: #9a9995;
$new-orange-toggle: #fdd900;
$new-orange-toggle-opacity: rgba($new-orange-toggle, 0.5);
$new-gray-toggle: #9a9995;
$new-gray-toggle-opacity: rgba($new-gray-toggle, 0.5);
$new-white-44-opacity: rgba($new-white, 0.44);
$new-gray-hover: #0000002b;
//Grosor del texto
$eyescloud-font-normal: 300;
$eyescloud-font-bold: 700;

$new-light: 300;
$new-regular: 400;
$new-semibold: 600;
$new-bold: 700;

//Tamaño de letra
$eyescloud-size-very-small: 0.375rem;
$eyescloud-size-small: 0.5rem;
$eyescloud-size-small-to-normal: 0.625rem;
$eyescloud-size-normal: 0.75rem;
$eyescloud-size-big: 1rem;

$new-font-5: 0.313rem;
$new-font-6: 0.375rem;
$new-font-7: 0.438rem;
$new-font-8: 0.5rem;
$new-font-9: 0.563rem;
$new-font-10: 0.625rem;
$new-font-11: 0.688rem;
$new-font-12: 0.75rem;
$new-font-13: 0.825rem;
$new-font-14: 0.875rem;
$new-font-15: 0.925rem;
$new-font-16: 1rem;
$new-font-17: 1.063rem;
$new-font-18: 1.125rem;
$new-font-19: 1.118rem;
$new-font-20: 1.25rem;
$new-font-21: 1.313rem;
$new-font-22: 1.375rem;
$new-font-24: 1.5rem;
$new-font-25: 1.563rem;
$new-font-26: 1.625rem;
$new-font-28: 1.75rem;
$new-font-30: 1.875rem;
$new-font-32: 2rem;
$new-font-34: 2.125rem;
$new-font-35: 2.188rem;
$new-font-36: 2.25rem;
$new-font-40: 2.5rem;
$new-font-41: 2.563rem;
$new-font-42: 2.625rem;
$new-font-44: 2.75rem;
$new-font-48: 3rem;
$new-font-50: 3.125rem;
$new-font-52: 3.25rem;
$new-font-54: 3.375rem;
$new-font-60: 3.75rem;
$new-font-64: 4rem;
$new-font-70: 4.375rem;
$new-font-72: 4.5rem;
$new-font-80: 5rem;
$new-font-100: 6.25rem;

$eyescloud-dark-blue: #004b87;
$eyescloud-red: #e92c0c;
$eyescloud-yellow: #f3a300;
$eyescloud-light-blue: #2672cb;
$eyescloud-gray: #4a4a4a;
$eyescloud-gray-light: #6c6c6c;
$eyescloud-04402e: #04402e;
$eyescloud-40b974: #40b974;
$eyescloud-298fa6: #298fa6;
$eyescloud-d9583b: #d9583b;
$eyescloud-3950ff: #3950ff;
$eyescloud-cc5600: #cc5600;
$eyescloud-601f73: #601f73;
$eyescloud-8c354c: #8c354c;
$eyescloud-720C5B: #720c5b;
$eyescloud-29a66c: #29a66c;
$eyescloud-A00062: #a00062;
$eyescloud-1f6373: #1f6373;
$eyescloud-13ac30: #13ac30;
$eyescloud-69b7bf: #69b7bf;
$eyescloud-8c4c35: #8c4c35;
$eyescloud-0367a6: #0367a6;
$eyescloud-208c65: #208c65;
$eyescloud-73a2bf: #73a2bf;
$eyescloud-8c1111: #8c1111;
$eyescloud-ebebeb: #ebebeb;
$eyescloud-f22259: #f22259;
$eyescloud-67145d: #67145d;
$eyescloud-8a8c42: #8a8c42;
$eyescloud-02735e: #02735e;
$eyescloud-d95204: #d95204;
$eyescloud-bf8f54: #bf8f54;
$eyescloud-fd7639: #fd7639;
$eyescloud-c4c3c3: #c4c3c3;
$eyescloud-009b6f: #009b6f;
$eyescloud-960325: #960325;
$eyescloud-0186a7: #0186a7;
$eyescloud-a00062: #a00062;
$eyescloud-7C0035: #7c0035;
$eyescloud-56ad34: #56ad34;
$eyescloud-AF0000: #af0000;
$eyescloud-40b974: #40b974;
$eyescloud-0A9595: #0a9595;
$eyescloud-770202: #770202;
$eyescloud-1e8121: #1e8121;
$eyescloud-1E8121: #1e8121;
$eyescloud-8b8b8b: #8b8b8b;
$eyescloud-0087A0: #0087a0;
$eyescloud-1387A0: #1387a0;
$eyescloud-770307: #770307;
$eyescloud-7C2C09: #7c2c09;
$eyescloud-F49600: #f49600;
$eyescloud-30A007: #30a007;
$eyescloud-000029: #000029;
$eyescloud-0000008E: #0000008e;
$eyescloud-878989: #878989;
$eyescloud-af050e: #af050e;
$eyescloud-B11F18: #b11f18;
$eyescloud-7AD9F0: #7ad9f0;
$eyescloud-D8D8D8: #d8d8d8;
$eyescloud-D0D0D0: #d0d0d0;
$eyescloud-F4F4F4: #f4f4f4;
$eyescloud-AAAAAA: #aaaaaa;

$header-size: 50px;
$white: #fff;

//MIXIN
//PHONE
@mixin phoneS {
  @media (min-width: 360px) {
    @content;
  }
}

@mixin phoneM {
  @media (min-width: 414px) {
    @content;
  }
}

@mixin phoneM_H {
  @media (min-width: 830px) and (orientation: landscape) {
    @content;
  }
}

//TABLET
@mixin tabletS {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin tabletM {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tabletX {
  @media (min-width: 834px) {
    @content;
  }
}

@mixin tabletS_H {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin tabletM_H {
  @media (min-width: 1194px) {
    @content;
  }
}

//screen
@mixin screenS {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin viewerScreenS {
  @media (min-height: 672px) and (min-width: 600px) {
    @content;
  }
}

@mixin screenM {
  @media (min-width: 1536px) {
    @content;
  }
}

@mixin screenL {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin screenX {
  @media (min-width: 1900px) {
    @content;
  }
}

@mixin viewerScreenX {
  @media (min-height: 928px) and (min-width: 600px) {
    @content;
  }
}

//Globales
@mixin btn($width, $heigth, $radius, $size, $uppercase: true) {
  width: $width;
  height: $heigth;
  border-radius: $radius;

  p {
    font-size: $size;
    font-weight: bold;

    @if ($uppercase) {
      text-transform: uppercase;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@mixin btn_img($width, $heigth, $radius, $size, $imgSize) {
  width: $width;
  height: $heigth;
  border-radius: $radius;

  p {
    font-size: $size;
    font-weight: bold;
    text-transform: uppercase;
  }

  img {
    width: $imgSize;
    height: auto;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

@mixin btn_disabled() {
  background-color: $new-gray !important;
  cursor: default !important;
  pointer-events: none !important;

  p {
    opacity: 0.5;
  }

  img {
    opacity: 0.5;
  }
}

@mixin btn_panel(
  $width,
  $heigth,
  $radius,
  $size,
  $imgSize,
  $flexDirection: column,
  $visible: block
) {
  width: $width;
  height: $heigth;
  border-radius: $radius;

  p {
    display: $visible;
    font-size: $size;
    font-weight: bold;
  }

  img {
    width: $imgSize;
    height: auto;
  }

  display: flex;
  flex-direction: $flexDirection;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

@mixin collapsible($size) {
  .collapsible-title {
    font-size: $size;
    color: $eyescloud-white;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
}

@mixin collapsible_title($size, $line) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  p {
    font-size: $size;
    line-height: $line;
    color: $white;
    font-weight: bold;
  }

  img {
    width: 16px;
    height: 9px;
    cursor: pointer;
  }
}

@mixin grid($col, $row, $space_row, $space_col) {
  display: grid;
  grid-template-columns: repeat($col, 1fr);
  grid-template-rows: repeat($row, 1fr);
  column-gap: $space_col;
  row-gap: $space_row;
}

@mixin line($margin) {
  width: 100%;
  height: 1px;
  background-color: $new-white;
  margin: $margin;
}

@mixin multilayer-minimized-panel(
  $width,
  $height,
  $radius,
  $padding,
  $widthImg,
  $heightImg
) {
  width: $width;
  height: $height;
  background-color: $eyescloud-orange;
  border-radius: $radius;
  position: absolute;
  float: right;
  right: 100%;
  top: 0;
  padding: $padding;
  cursor: pointer;

  img {
    width: $widthImg;
    height: $heightImg;
  }
}

@mixin scroll() {
  ::-webkit-scrollbar {
    background-color: transparent;
    border: 1px solid $eyescloud-white;
    border-radius: 10px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $eyescloud-white;
  }
}

@mixin flex($direction: row, $align: center, $justify: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin leftPanelButton(
  $width,
  $height,
  $fontSize,
  $lineHeight,
  $padding,
  $letterSpacing,
  $iconHeight,
  $marginBottom
) {
  width: $width;
  height: $height;

  .button-container {
    font-size: $fontSize;
    line-height: $lineHeight;
    padding: $padding;
    letter-spacing: $letterSpacing;
  }

  .icon {
    height: $iconHeight;
    margin-bottom: $marginBottom;
  }
}

@mixin viewerButton($width, $height, $radius, $size, $margin) {
  @include flex();
  font-weight: bold;
  cursor: pointer;
  width: $width;
  height: $height;
  border-radius: $radius;
  font-size: $size;
  margin: $margin;
}

// Template orden includes pwa

// @include phoneM() {
// }

// @include tabletS() {
// }

// @include tabletM() {
// }

// @include phoneM_H() {
// }

// @include tabletX() {
// }

// @include tabletS_H() {
// }

// @include tabletM_H() {
// }

// @include screenS() {
// }

// @include screenM() {
// }

// @include screenL() {
// }

// @include screenX() {
// }
