
/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=13p0u0kex8W2uqu8af7lEqaulDE */

/* Feel free to copy&paste color codes to your application */

/* As hex codes */
.color-primary-0 { color: #19282C }	/* Main Primary color */
.color-primary-1 { color: #7A8184 }
.color-primary-2 { color: #39474B }
.color-primary-3 { color: #2D6D82 }
.color-primary-4 { color: #108FB9 }

/* As RGBa codes */
.rgba-primary-0 { color: rgba( 25, 40, 44,1) }	/* Main Primary color */
.rgba-primary-1 { color: rgba(122,129,132,1) }
.rgba-primary-2 { color: rgba( 57, 71, 75,1) }
.rgba-primary-3 { color: rgba( 45,109,130,1) }
.rgba-primary-4 { color: rgba( 16,143,185,1) }

/* Generated by Paletton.com Â© 2002-2014 */
/* http://paletton.com */




:root{
	
	--color-0: 			rgba( 25, 40, 44, 1);
	--color-1: 			rgba(122,129,132, 1);
	--color-2: 			rgba( 57, 71, 75, 1);
	--color-3: 			rgba( 45,109,130, 1);
	--color-4: 			rgba( 16,143,185, 1);
	
	--bg-color:			var(--color-0);
	--bg-color-2:		rgb(60, 80, 85);
	--bg-light-color:	rgba( 48, 61, 65, 1);
	--bg-dark-color:	rgba( 24, 31, 33, 1);
	--bg-hover-color:	var(--color-2);
	
	--font-color:		#9AA1A4;
	--font-color-2:		#ddd;
	--font-color:		#cccccc;
	--border-color:		black;
	
	--measurement-detail-node-bg-light:		var(--color-1);
	--measurement-detail-node-bg-dark:		var(--color-2);
	--measurement-detail-area-bg-color:		#eee;

	
}

#potree_sidebar_container{
	position:	absolute;
	z-index:	0;
	width:		350px;
	height:		100%;
	overflow-y:	scroll;
	font-size:	85%;
	border-right:	1px solid black;
	background-color:	var(--bg-color);
}

#sidebar_root{
	color:				var(--font-color);
	font-family:		Arial,Helvetica,sans-serif;
	font-size:			1em;
}

.potree_failpage{
	width: 100%;
	height: 100%;
	background-color: white;
	position: absolute;
	margin: 15px;
}

.potree_failpage a{
	color: initial !important;
	text-decoration: underline !important;
}

.potree_info_text{
	color:		white;
	font-weight: bold;
	text-shadow:  1px  1px 1px black,
				  1px -1px 1px black,
				 -1px  1px 1px black,
				 -1px -1px 1px black;
}

.potree_message{
	width: 500px;
	background-color: var(--bg-color);
	padding: 5px;
	margin: 5px;
	border-radius: 4px;
	color: var(--font-color);
	font-family: Arial;
	opacity: 0.8;
	border: 1px solid black;
	display: flex;
	overflow: auto;
}

.potree_message_error{
	background-color: red;
}

#potree_description{
	position: absolute; 
	top: 10px; 
	left: 50%; 
	transform: translateX(-50%); 
	text-align: center;
	z-index:	1000;
}

.potree_sidebar_brand{
	margin:			1px 20px;
	line-height:	2em;
	font-size:		100%;
	font-weight:	bold;
	position:		relative;
	display:		flex; 
	flex-direction:	row;
}

#potree_sidebar_container a{
	color: 			#8Aa1c4;
}

#potree_quick_buttons{
	position: absolute;
	left: 4px;
	top: 4px; 
	width: 10px; 
	height: 10px; 
	z-index: 10000;
	float: left;
}

.potree_menu_toggle{
	float:			left;
	margin:			0;
	background:		none;
	width:			2.5em;
	height:			2.5em;
	z-index:		100;
	cursor: 		pointer;
	margin:			4px;
}

#potree_map_toggle{
	float:			left;
	margin:			0;
	background:		none;
	width:			2.5em;
	height:			2.5em;
	z-index:		100;
	cursor: 		pointer;
	margin:			4px;
}

#potree_render_area{
	position: 	absolute;
	/*background: linear-gradient(-90deg, red, yellow);*/
	top: 		0px;
	bottom: 	0px;
	left: 		0px;
	right: 		0px;
	overflow: 	hidden;
	z-index: 	1;
	-webkit-transition: left .35s;
	transition: left .35s;
}

.potree-panel {
	border: 		1px solid black;
	border-radius: 	0.4em;
	padding: 		0px;
	background-color: var(--bg-light-color);
}

.potree-panel-heading{
	background-color: var(--bg-dark-color);
}

a:hover, a:visited, a:link, a:active{
	color: 				#ccccff;
	text-decoration: 	none;
}

.annotation{
	position:		absolute;
	padding:		10px;
	opacity:		0.5;
	transform:		translate(-50%, -30px);
	will-change:	left, top;
}

.annotation-titlebar{
	color:			white;
	background-color:	black;
	border-radius:	1.5em;
	border:			1px solid rgba(255, 255, 255, 0.7);
	font-size:		1em;
	opacity:		1;
	margin:			auto;
	display:		table;
	padding:		1px 8px;
	cursor: 		pointer;
}

.annotation-expand{
	color:			white;
	font-size:		0.6em;
	opacity:		1;
}

.annotation-action-icon{
	width:			20px;
	height:			20px;
	display:		inline-block;
	vertical-align:	middle;
	line-height:	1.5em;
	text-align:		center;
	font-family:	Arial;
	font-weight:	bold;
	cursor: 		pointer;
}

.annotation-action-icon:hover{
	filter:			drop-shadow(0px 0px 1px white);
	width:			24px;
	height:			24px;
	cursor: 		pointer;
	
}

.annotation-item {
	color:			white;
	background-color: 	black;
	opacity:		0.5;
	border-radius:	1.5em;
	font-size:		1em;
	line-height:	1.5em;
	padding:		1px 8px 0px 8px;
	font-weight:	bold;
	display:		flex;
	cursor:			default;
}

.annotation-item:hover {
	opacity:		1.0;
	box-shadow:		0 0 5px #ffffff;
}

.annotation-main{
	display:		flex;
	flex-grow:		1;
}

.annotation-label{
	display:		inline-block;
	height:			100%;
	flex-grow:		1;
	user-select:	none;
	-moz-user-select: none;
	z-index:		100;
	vertical-align:	middle;
	line-height:	1.5em;
	font-family:	Arial;
	font-weight:	bold;
	cursor: 		pointer;
	white-space:	nowrap;
}

.annotation-description{
	position:		relative;
	color:			white;
	background-color:	black;
	padding:		10px;
	margin:			5px 0px 0px 0px;
	border-radius:	4px;
	display:		none;
	max-width:		500px;
	width:			500px;
}

.annotation-description-close{
	filter:			invert(100%);
	float:			right;
	opacity:		0.5;
	margin:			0px 0px 8px 8px;
}

	
.annotation-description-content{
	color:			white;
}

.annotation-icon{
	width:		20px;
	height:		20px;
	filter:		invert(100%);
	margin:		2px 2px;
	opacity:	0.5;
}


canvas { 
	width: 100%; 
	height: 100% 
}

/* body{ 
	margin: 	0; 
	padding: 	0;
	position:	absolute;
	width: 		100%;
	height: 	100%;
	overflow:	hidden;
} */

.axis {
  font: 		10px sans-serif;
  color: 		var(--font-color);
}

.axis path{
	fill: 		rgba(255, 255, 255, 0.5);
	stroke: 		var(--font-color);
	shape-rendering: crispEdges;
	opacity: 		0.7;
}

.axis line {
	fill: 		rgba(255, 255, 255, 0.5);
	stroke: 		var(--font-color);
	shape-rendering: crispEdges;
	opacity: 		0.1;
}

.tick text{
	font-size: 12px;
}

.scene_header{
	display:flex;
	cursor: pointer;
	padding: 2px;
}

.scene_content{
	padding: 5px 0px 5px 0px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.measurement_content{
	padding: 5px 15px 5px 10px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.propertypanel_content{
	padding: 5px 15px 5px 10px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.measurement_value_table{
	width: 100%;
}

.coordinates_table_container table td {
	width: 33%;
	text-align: center;
}

#scene_object_properties{
	margin:		0px;
}




.pv-panel-heading{
	padding: 	4px !important;
	display: 	flex; 
	flex-direction: row
}

.pv-menu-list{
	list-style-type:	none;
	padding:			0;
	margin:				15px 0px;
	overflow:			hidden;
}

.pv-menu-list > *{
	margin: 	4px 20px;
}

.ui-slider {
	margin-top: 5px;
	margin-bottom: 10px;
	background-color: 	var(--color-1) !important;
	background: 		none;
	border: 			1px solid black;
}

.ui-selectmenu-button.ui-button{
	width: 		100% !important;
}

.pv-menu-list > li > .ui-slider{
	background-color: 	var(--color-1) !important;
	background: 		none;
	border: 			1px solid black;
}

.pv-menu-list .ui-slider{
	background-color: 	var(--color-1) !important;
	background: 		none;
	border: 			1px solid black !important;
}

.ui-slider-handle{
	border: 			1px solid black !important;
}

.ui-widget{
	box-sizing:border-box
}

.panel-body > li > .ui-slider{
	background-color: var(--color-1) !important;
	background: none;
	border: 1px solid black;
}

.panel-body > div > li > .ui-slider{
	background-color: var(--color-1) !important;
	background: none;
	border: 1px solid black;
}

.pv-select-label{
	margin: 1px;
	font-size: 90%;
	font-weight: 100;
}

.button-icon:hover{
	/*background-color:	#09181C;*/
	filter:				drop-shadow(0px 0px 4px white);
}

.ui-widget-content{
	/*color: var(--font-color) !important;*/
}

.accordion > h3{
	background-color: var(--bg-color-2) !important;
	background: #f6f6f6 50% 50% repeat-x;
	border:		1px solid black;
	color:		var(--font-color-2);
	cursor:		pointer;
	margin:		2px 0 0 0;
	padding:	4px 10px 4px 30px;
	box-shadow:	0px 3px 3px #111;
	text-shadow:	1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	font-size:	1em;
}

.accordion > h3:hover{
	filter: brightness(125%);
}

.accordion-content{
	padding: 0px 0px !important;
	border: none !important;
}

.icon-bar{
	height: 4px !important;
	border: 1px solid black;
	background-color: white;
	border-radius: 2px;
}

.canvas{
	-webkit-transition: top .35s, left .35s, bottom .35s, right .35s, width .35s;
	transition: top .35s, left .35s, bottom .35s, right .35s, width .35s;
}

#profile_window{
	background-color:	var(--bg-color);
}

#profile_titlebar{
	background-color:	var(--bg-color-2);
	color: var(--font-color-2);
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	font-size: 1em;
	font-weight: bold;
}

#profile_window_title{
	position: absolute;
	margin: 5px;
}

.profile-container-button{
	cursor: pointer;
}

.profile-button:hover{
	background-color: #0000CC;
}

.unselectable{
	user-select: 			none;
}

.selectable{
	user-select: 			text;
}



.divider {
	display: 		block;
	text-align: 	center;
	overflow: 		hidden;
	white-space: 	nowrap; 
	font-weight:	bold;
	font-size:		90%;
	letter-spacing:	1px;
	margin-left:	0px;
	margin-right:	0px;
	margin-top: 	1px;
	margin-bottom: 	1px;
	padding:		1px !important;
}

.divider > span {
	position: 	relative;
	display: 	inline-block;
}

.divider > span:before,
.divider > span:after {
	content: 	"";
	position: 	absolute;
	top: 		50%;
	width: 		9999px;
	height: 	1px;
	background: #b2b2b2;
}

.divider > span:before {
	right: 100%;
	margin-right: 5px;
}

.divider > span:after {
	left: 100%;
	margin-left: 5px;
}











.ol-dragbox {
  background-color: rgba(255,255,255,0.4);
  border-color: rgba(100,150,0,1);
  border: 1px solid red;
}

.text-icon{
	opacity:	0.5;
	height:		24px;
}

.text-icon:hover{
	opacity:	1.0;
}

.input-grid-cell{
	flex-grow: 1; margin: 0px 3px 0px 3px;
}

.input-grid-label{
	flex-grow: 1; 
	margin: 0px 3px 0px 3px; 
	text-align:center; 
	font-weight: bold;
}

.input-grid-cell > input{
	width: 100%
}

.invalid_value{
	color: #e05e5e;
}


































/** 
 * OVERRIDES
 */


.ui-spinner-input{
	color: black;
}

.jstree-themeicon-custom{
	background-size: 16px !important;
}

.jstree-default .jstree-clicked{
	/*background-color: #ffffff !important;*/
	background-color: #34494f !important;
}

.jstree-default .jstree-hovered{
	background-color: #34494f !important;
}

.jstree-anchor{
	width: 100% !important;
}

.ui-state-default{
	background: #a6a9aa !important;
	border: 1px solid black;
	color: black;
}

.ui-state-active{
	background: #c6c9ca !important;
	color: black !important;
}

.cesium-viewer .cesium-viewer-cesiumWidgetContainer{
	position: absolute;
	height: 100%;
	width: 100%;
}




.zs_widget{
	padding: 2px;
	height: 4em;
	user-select: none;
}
.zs_core{
	overflow: hidden;
	position: relative;
	height: 100%;
}
.zs_handle{
	position: absolute;
	top: 0px;
	bottom: 0px;
	border: 1px solid black;
	border-radius: 3px;
	background-color: rgb(166, 169, 170);
	width: 8px;
	user-select: none;
	width: 1.2em;
	height: 1.2em;
	top: calc(50% - 0.6em);
}
.zs_stretch{
	position: absolute;
	top: 0px;
	bottom: 0px;
	border: 1px solid black;
	border-radius: 3px;
	background-color: rgb(166, 169, 170);
	width: 8px;
	user-select: none;
	width: 1.2em;
	height: 1.2em;
	top: calc(50% - 0.6em);
	color: black;
	font-weight: bold;
	font-size: 1.2em;
	font-family: arial;
}
.zs_handle:hover{
	background-color: lightgreen;
}
.zs_inside{
	position: absolute !important;
	width: 100%;
	border: 1px solid black;
	background-color: white;
	top: calc(50% - 0.326em);
	height: 0.652em;
	cursor: zoom-in;
}
.zs_outside{
	position: absolute !important;
	width: 100%;
	background-color: var(--color-1) !important;
	top: calc(50% - 0.326em);
	height: 0.652em;
	cursor: zoom-in;
}
.zs_visible_range_label{
	position: absolute;
	bottom: 0px;
	pointer-events:none;
}
.zs_visible_range_label_left{
	left: 0px;
}
.zs_visible_range_label_right{
	right: 0px;
}
.zs_chosen_range_label{
	position: absolute;
	pointer-events:none;
}

/* #potree_sidebar_container{
	scrollbar-color: var(--color-1) var(--bg-color);
	scrollbar-width: thin;
} */



/* ::-webkit-scrollbar {
	width: 6px;
	background-color: var(--bg-color);
}

::-webkit-scrollbar-track {

}

::-webkit-scrollbar-thumb {
	background-color: var(--color-1);
} */

.propertypanel_content .heading{
	font-weight: bold;
	padding-top: 0.6em;
	padding-bottom: 0.1em;
}